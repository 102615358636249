/* Shop page */
.shop-page-container {
  background-color: white;
  margin-top: 10vh !important;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.shop-details-carousel-img {
  width: 100%;
  aspect-ratio: 1;
  max-height: 50vh;
  border-radius: 0.5rem;
}
