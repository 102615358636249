.home-white-container {
  background-color: white;
}

.home-subsection-main-container {
  padding-left: 5vw;
  padding-right: 5vw;
}

.home-shops-container {
  padding-left: 5vw;
}

.home-subsection-rounded-container {
  background-color: white;
  border-radius: 50px;
  margin-left: 5vw;
  margin-right: 5vw;
  padding-top: 4vh;
  padding-bottom: 5vh;
}

.home-subsection-title {
  font-size: 1.5rem;
  color: #ff9502;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .home-subsection-title {
    font-size: 1rem;
    color: #ff9502;
    text-transform: uppercase;
  }
}

.home-subsection-subtitle {
  font-size: 3.5rem;
}

@media (max-width: 575px) {
  .home-subsection-subtitle {
    font-size: 2rem;
  }
}

.home-subsection-description {
  font-size: 16px;
}

/* Text */
h1#home-main-title {
  font-size: 4.5rem;
  text-align: center;
  margin-top: 48px;
}

@media (max-width: 575px) {
  h1#home-main-title {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 48px;
  }
}

#home-main-text {
  font-size: 20px;
  text-align: center;
}

#home-main-buttons-container {
  margin-top: 40px;
}

/* Image */
#home-gift-logo-container-left {
  margin-top: 72px;
  text-align: right;
}

#home-gift-logo-left {
  width: 90%;
  max-width: 400px;
}

#home-gift-logo-container-right {
  margin-top: 180px;
  text-align: left;
}

#home-gift-logo-right {
  width: 90%;
  max-width: 460px;
}
