/* Shop card */
.shop-card {
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 10px;
  border-width: 1px;
  width: 100%;
  max-width: 330px;
  min-height: 460px;
  position: relative;
  background-color: white;
  cursor: pointer;
}

.shop-card-img-container {
  margin-left: auto;
  margin-right: auto;
  width: 92% !important;
  aspect-ratio: 0.55;
  max-height: 242px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0.5rem;
  border-width: 1px;
}

.shop-card-img {
  width: 100%;
  aspect-ratio: 0.55;
  max-height: 242px;
  border-radius: 0.5rem;
}

.shop-card-img-skeleton {
  width: 92% !important;
  aspect-ratio: 0.55;
  max-height: 242px;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
}

.shop-card-logo {
  position: absolute;
  margin-top: 12px;
  margin-left: 28px;
  width: 70px;
  height: 70px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 50%;
  border-width: 1px;
  overflow: hidden;
  z-index: 1;
}

.shop-card-town {
  font-size: 1rem;
}

.shop-card-title {
  font-size: 1.125rem;
  font-family:
    oceanwide-bold,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-weight: bold;
}

.shop-card-description {
  font-size: 0.95rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shop-card-read-more-text {
  font-size: 0.95rem;
}

.shop-card-description-mobile-collapsed {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.shop-card-description-mobile-expanded {
  display: block;
}

.shop-card-tags-container {
  margin-bottom: 64px;
}

.shop-card-category {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.82rem;
  background-color: #ffcdde;
  color: #104127;
  border-radius: 5px;
  display: inline-block;
}

.shop-card-icons-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.shop-card-icon {
  cursor: pointer;
}
