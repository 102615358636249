.gift-card-preview-container {
  padding: 15px;
  height: fit-content;
  border-radius: 0.375rem;
  border: 1px solid #dee2e6;
  display: flex;
}

.gift-card-preview-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gift-card-preview-info-line {
  margin-bottom: 5px;
}

.gift-card-message {
  word-wrap: break-word;
  white-space: pre-wrap;
}
