.gift-card-graphics-img-container {
  margin: 5px;
  height: 400px;
  border-radius: 25px;
  border: 1px solid #ffffff;
}

.gift-card-graphics-img-container-selected {
  margin: 5px;
  height: 400px;
  border-radius: 25px;
  border: 1px solid #104127;
}

.gift-card-graphics-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  cursor: pointer;
}
