@import "node_modules/bootstrap/scss/bootstrap";

.btn-violet {
  @extend .btn-primary;

  &,
  &:visited {
    background-color: #7000c8 !important;
    border-color: #7000c8 !important;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
  }

  &:hover,
  &:active {
    background-color: #4a0085 !important;
    border-color: #4a0085 !important;
  }
}

.btn-green {
  @extend .btn-primary;

  &,
  &:visited {
    background-color: #104127 !important;
    border-color: #104127 !important;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
  }

  &:hover,
  &:active {
    background-color: #0e3a23 !important;
    border-color: #0e3a23 !important;
  }
}

.btn-green-outline {
  @extend .btn-primary;

  &,
  &:visited {
    background-color: #10412700 !important;
    border-color: #104127 !important;
    color: #104127;
    border-radius: 50px;
    padding: 10px 20px;
  }

  &:hover,
  &:active {
    background-color: #104127 !important;
    border-color: #104127 !important;
    color: #fff !important;
  }
}

.btn-disabled {
  @extend .btn-primary;

  &,
  &:visited {
    background-color: #aaaeb1 !important;
    border-color: #aaaeb1 !important;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
  }

  &:hover,
  &:active {
    background-color: #5c636a !important;
    border-color: #aaaeb1 !important;
    color: white;
  }
}
