/* Change the background color of the active (open) accordion header */
.accordion-button:not(.collapsed) {
  background-color: #f4ebfb !important; /* Your custom active background color */
  font-weight: bold; /* Optional: make text bold */
  color: black;
}

/* Optional: Change the background color of the inactive (closed) accordion header */
.accordion-button.collapsed {
  background-color: #fff;
  color: #333;
}
