@font-face {
  font-family: oceanwide;
  src:
    local("oceanwide"),
    url("../fonts/oceanwide-regular.woff") format("woff");
}

@font-face {
  font-family: oceanwide-bold;
  src:
    local("oceanwide-bold"),
    url("../fonts/oceanwide-bold.woff") format("woff");
}

body {
  background-color: #f0f9d7 !important;
  margin: 0;
  font-family:
    oceanwide,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

p,
span,
a,
div {
  font-family:
    oceanwide,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

header.header-nav {
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.header-logo {
  width: 50vw;
  max-width: 350px;
}

footer {
  margin-top: 5vh;
}

.footer-left-item {
  margin-left: 100px;
}

@media (max-width: 991px) {
  .footer-left-item {
    margin-left: 100px;
    margin-right: 100px;
  }
}

/* Heading text */
h1,
h2,
h3,
h4,
h5 {
  font-family:
    oceanwide-bold,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

.main-text {
  font-size: 20px;
  text-align: center;
}

/* Maintenance */
@media only screen and (max-width: 680px) {
  .maintenance-subtitle {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 681px) {
  .maintenance-subtitle {
    font-size: 4.5rem;
  }
}

/* Header */
#logo {
  margin-top: 40px;
  margin-left: 40px;
}

/* Buttons */
.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #7000c8;
  border-color: #7000c8;
}

.btn-primary:hover {
  background-color: #560097;
  border-color: #560097;
}

/* Textbox with icon */
.textbox-with-icon-title {
  font-size: 24px;
}

.textbox-with-icon-description {
  font-size: 16px;
}

/* Loading image */
.image-loading-hover {
  overflow: hidden;
  transition-duration: 0.8s;
  transition-property: transform;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
}

.image-loading {
  overflow: hidden;
  animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Carousel */
.custom-carousel-icon {
  width: 24px;
  height: 24px;
}

.custom-carousel-icon:hover {
  opacity: 0.8;
  width: 24px;
  height: 24px;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
  transition: opacity 0.15s ease;
}

.multi-carousel-left-arrow,
.multi-carousel-right-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.multi-carousel-left-arrow:hover,
.multi-carousel-right-arrow:hover {
  opacity: 0.8;
}

.multi-carousel-left-arrow {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.multi-carousel-right-arrow {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.multi-carousel-icon {
  width: 36px;
  height: 36px;
}

/* Shop map */
.shop-map-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 42.86%;
}

.shop-map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .shop-map-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%;
  }
}

@media (max-width: 575px) {
  .shop-map-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 100%;
  }
}
