.amount {
  width: 80px;
  margin: 5px;
  padding: 10px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  border: 1px solid #104127;
  border-radius: 25px;
  cursor: pointer;
}

.amount-selected {
  width: 80px;
  margin: 5px;
  padding: 10px;
  background-color: #104127;
  color: #ffffff;
  text-align: center;
  border: 1px solid #104127;
  border-radius: 25px;
  cursor: pointer;
}
